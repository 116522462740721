$icon-path-search: '../../../../public/static/images/search/icons/icon-search.svg';
$icon-path-reset: '../../../../public/static/images/new_search/icons/icon-reset.svg';
$icon-path-pin: '../../../../public/static/images/new_search/icons/icon-pin.svg';

.new-search-input {
  flex: 1 1;
  border: 0;
  height: 48px;
  outline: none;
  font-size: 15px;
  appearance: none;
  border-radius: 14px;
  box-sizing: border-box;
  font-family: $helvetica;
  width: calc(100% - 10px);
  padding: 10px 0 10px 35px;
  background-color: transparent;

  &__container {
    flex: 1 0;
    display: flex;
    align-items: center;
    border-radius: 14px;
    box-sizing: border-box;
    font-family: $helvetica;
    background-size: 18px 18px;
    background-repeat: no-repeat;
    background-position: 10px 50%;
    background-image: url($icon-path-search);
    border: 1px solid $colour-grey-wild-sand;
    background-color: $colour-background-tertiary;

    &.is-pin {
      background-size: 21px 21px;
      background-image: url($icon-path-pin);
    }
  }

  &__reset-btn {
    width: 32px;
    height: 38px;
    padding: 10px;
    box-sizing: border-box;
    background-size: 12px 12px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-image: url($icon-path-reset);
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    appearance: none;
  }

  &::placeholder {
    font-size: 15px;
    font-family: $helvetica;
    color: $colour-heathered-grey;
  }
}
