.new-search-checkbox-list {
  $search-checkbox: &;

  &--dropdown {
    transition: 0.5s;
    overflow: hidden;
    padding-left: 35px;
    width: 100%;

    &__main {
      position: relative;
      height: 55px;
      border-bottom: 0.5px solid $colour-grey-wild-sand;
      background-color: #fff;
      padding-left: 15px;
    }

    &__collapsed {
      height: 0;
    }
  }
}
