.new-tabs {
  $tabs: &;

  &__list {
    display: flex;
    background-color: $colour-white;

    #{$tabs}--locations & {
      padding: 15px;
      background-color: $colour-white;
    }

    &_i {
      $tab: &;
      flex: 1 1 0;
      text-align: center;
      font-weight: 500;
      font-family: $helvetica;
      text-decoration: none;

      #{$tabs}--quick-links & {
        $tab-border-width: 3px;
        $tab-height: 45px;
        height: $tab-height;
        line-height: ($tab-height + $tab-border-width);
        font-size: 12px;
        color: $colour-cocoa-brown;
        border-bottom: 1px solid $colour-grey-bon-jour;

        &--selected {
          color: $colour-neon-blue;
          border-color: $colour-neon-blue;
          border-width: $tab-border-width;
        }
      }

      #{$tabs}--locations & {
        $tab-height: 38px;
        $border-radius: 20px;
        font-weight: 400;
        height: $tab-height;
        line-height: $tab-height;
        font-size: 15px;
        color: $colour-cocoa-brown;
        border: 1px solid #ededed;
        background-color: $colour-white;

        + #{$tab} {
          margin-left: -1px;
        }

        &:first-of-type {
          border-top-left-radius: $border-radius;
          border-bottom-left-radius: $border-radius;
        }

        &:last-of-type {
          border-top-right-radius: $border-radius;
          border-bottom-right-radius: $border-radius;
        }

        &--selected {
          color: $colour-neon-blue;
          border-color: $colour-neon-blue;
          background-color: $colour-lavender;
          z-index: 1;
        }

        span {
          margin-left: 3px;
        }
      }
    }
  }

  &__content {
    &_pane {
      &--scrollable {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}
