.calc {
  font-family: $helvetica;
  color: #09101d;

  &__header {
    position: relative;
    width: 100%;
    height: 70px;
    padding: 0 15px;
    background-color: #fff;
    border-bottom: 1px solid #e8ecf1;

    &-close-icon {
      position: absolute;
      top: 25px;
      right: 15px;
      cursor: pointer;
    }

    &-logo {
      display: flex;
      justify-content: flex-start;
      height: 100%;
      align-items: center;
    }
  }

  &__content {
    padding: 0 15px 30px;

    &-title {
      padding: 25px 0;
      text-align: center;
      font-size: 24px;
      font-weight: 500;

      &--success {
        color: #2e2e2e;
      }
    }
  }

  &__btn {
    width: 100%;
    height: 50px;
    margin-top: 10px;
    border-radius: 7px;
    background-color: #a4243b;
    color: #fff;
    font-size: 18px;
    font-weight: 700;

    &-transparent {
      margin-top: 20px;
      background-color: #fff;
      color: #a4243b;
      border: 1px solid #a4243b;
    }

    &-info {
      display: block;
      color: #09101d;
      font-size: 12px;
      line-height: 24px;
      margin: 20px 0 15px;
    }
  }

  &__error {
    color: #ca1016;
    font-size: 12px;
    margin: 5px 0 15px;

    &-space {
      margin: 0 !important;
    }

    span {
      display: block;
    }
  }
}
