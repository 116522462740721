.cur-location {
  &__address {
    position: relative;
    font-family: $helvetica;
    font-size: 15px;
    font-weight: 500;
    color: #2f1f19;

    span {
      display: block;
      max-width: 90%;
    }

    i {
      position: absolute;
      top: 0;
      right: 0;
      margin-left: 5px;

      svg {
        margin-top: -4px;
      }
    }
  }

  &__map {
    width: 100%;
    height: 75px;
    margin: 15px 0;
    border-radius: 7px;
    background-color: #f0f0f0;
    background-image: url('/static/images/map_stub.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    cursor: pointer;

    &::after {
      content: '';
      display: block;
      width: 28px;
      height: 39px;
      position: absolute;
      background-image: url('/static/images/item/blue-pin.svg');
      background-repeat: no-repeat;
      background-size: contain;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    img {
      display: block;
      margin: 0 auto;
    }
  }

  &__locations {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    &-i {
      padding: 10px 15px;
      border-radius: 20px;
      background-color: #f8f8f8;
      color: #4264fd;
      font-family: $helvetica;
      font-size: 15px;
      text-decoration-line: underline;
    }
  }
}

.cur-map {
  &__container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100vh;
  }

  &__header {
    position: absolute;
    width: 100%;
    height: 60px;
    padding: 0 15px;
    border-bottom: 1px solid #eae5e1;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;

    span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 91%;
      color: #2f1f19;
      line-clamp: 1;
      font-family: $helvetica;
      font-size: 16px;
      font-weight: 500;
    }

    i {
      margin-left: 15px;
    }
  }

  &__marker {
    position: absolute;
    width: 50px;
    height: 60px;
    left: -25px;
    top: -45px;
    font-size: 0;
    background-position: center;
    background-size: contain;
    background-image: url('/static/images/item/location-pin.svg');
  }
}
