.report-section {
  display: flex;
  height: 55px;
  padding: 0 15px;
  align-items: center;
  gap: 5px;
  border-bottom: 1px solid #eae5e1;
  background-color: #fff;
  cursor: pointer;

  &__title {
    color: #2f1f19;
    font-family: $helvetica;
    font-size: 15px;

    &--underline {
      text-decoration-line: underline;
    }
  }

  &__list {
    max-height: 330px;
    overflow: auto;
    padding-bottom: env(safe-area-inset-bottom);

    &-i {
      display: flex;
      justify-content: space-between;
      gap: 15px;
      min-height: 55px;
      padding: 5px 0;
      align-items: center;
      border-bottom: 1px solid #eae5e1;

      &-title {
        color: #2f1f19;
        font-size: 15px;
      }
    }
  }

  &__inputs-container {
    &-item {
      min-height: 60px;
      margin-bottom: 10px;
      border-bottom: 1px solid #eae5e1;

      &-email {
        height: 60px;
      }

      &--blur {
        span {
          height: 5px !important;
        }
      }

      &--report {
        margin-bottom: 0 !important;
      }

      span {
        display: block;
        color: #98918a;
        font-family: $helvetica;
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 11px;
        height: 15px;
      }
    }

    textarea:focus,
    input:focus {
      outline: none;
    }

    input,
    textarea {
      resize: none;
      border: 0;
      padding-right: 0;
      padding-left: 0;
      border-radius: 0;
      box-shadow: none;
      color: #2f1f19;
      font-family: $helvetica;
      font-size: 15px;
      height: auto;
      width: 100%;
    }

    textarea {
      height: 120px;
    }
  }
}
