.cur-item-gallery {
  height: 330px;
  position: relative;

  &__bookmark {
    z-index: 10000 !important;
    position: fixed !important;
    top: 22px !important;
    right: 15px !important;
  }

  &__modal {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-self: center;
    align-items: center;
    z-index: 10000;
    width: 100%;
    font-family: $helvetica;

    &-header {
      padding: 0 15px;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      height: 60px;
      top: 0;
      right: 0;
    }

    &-footer {
      position: fixed;
      bottom: 0;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      gap: 10px;

      &--animate-opacity {
        opacity: 0.0001;
        will-change: opacity;
        transition: opacity 700ms cubic-bezier(0.4, 0, 0.22, 1);
      }

      &-info {
        color: #fff;
        font-size: 12px;

        &-title {
          font-size: 18px;
          font-weight: 500;
        }

        &-desc {
          font-size: 12px;
        }

        span {
          display: block;
        }
      }
    }
  }

  &__info-container {
    position: absolute;
    display: flex;
    justify-content: space-between;
    bottom: 10px;
    padding: 0 15px;
    height: 25px;
    width: 100%;
    z-index: 1;

    &-icons {
      background-color: #fff;
      border-radius: 7px;

      i {
        svg {
          margin: 6px 5px;
        }
      }
    }

    &-counter {
      color: #fff;
      align-self: center;
      padding: 5px 15px;
      font-family: $helvetica;
      font-size: 12px;
      border-radius: 7px;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }

  &__thumbnail {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 330px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;

    &-img {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }

  &--hidden {
    display: none;
  }
}

.pswp {
  z-index: 9999 !important;
}
