.toggle-btn {
  width: 51px;
  height: 31px;
  background-color: #eae5e1;
  border-radius: 25px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &__active {
    background-color: #3db460;
  }

  &__circle {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 25px;
    height: 25px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 3px;
    left: 3px;
    transition: left 0.3s ease;

    &__active {
      left: 22px;
    }
  }
}
