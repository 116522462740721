.section {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 10px;
    box-sizing: border-box;
    background-color: #fff;
    margin: 0 0 10px;
    border-top: 0.5px solid #ededed;
    text-transform: initial;

    &.bookmarks {
      margin-top: -12px;
      margin-left: 0;
      margin-right: 0;
    }

    .thumblist-title {
      font-size: 13px;
      line-height: 20px;
      text-transform: uppercase;
      font-weight: bold;
      color: #483b36;
      margin: 0;
    }

    .thumblist-premium-title {
      font-size: 11px;
      font-weight: 400;
      color: #bcbcbc;
      margin: 0;
    }

    .show-all {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: #3c5efd;
    }

    .items-header--items-found {
      font-size: 14px;
      line-height: 16px;
      color: #9b9b9b;
    }
  }
}
