.new-location-tabs {
  padding-bottom: 65px;

  &__title {
    height: 53px;
    line-height: 1.2;
    padding: 24px 15px 0;
    border-top: 1px solid $colour-grey-alabaster;
    border-bottom: 1px solid $colour-grey-alabaster;
    background-color: $colour-grey-desert-storm;
    font-size: 11px;
    font-weight: 500;
    color: $colour-heathered-grey;
    text-transform: uppercase;
  }
}
