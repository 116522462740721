.new-search-empty {
  padding: 65px 15px 0;

  &__icon {
    width: 30px;
    height: 30px;

    img {
      width: 100%;
    }
  }

  &__title {
    margin: 15px 0;
    font-size: 16px;
    font-weight: 500;
    color: #2f1f19;
  }

  &__link {
    color: #4264fd;
    padding: 0;
    margin-bottom: 90px;
  }
}
