$title-color: #2f1f19;
$border-color: #eae5e1;

.new-search {
  &-container {
    display: flex;
    background-color: #fff;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    &_with-search-type {
      padding-top: 55px;
    }
  }

  &__text {
    color: $title-color;
    font-size: 15px;
    font-weight: 400;
  }

  &__chips-wrapper {
    display: flex;
    background-color: #fff;
    flex-direction: row;
    align-items: center;
    padding: 10px 15px 0;
    width: 100%;
    overflow-x: scroll;
    -webkit-overflow-scrolling: auto !important;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__tabs-wrapper {
    padding: 15px 15px 0 !important;
  }

  &__link {
    text-decoration: none;
  }
}
