.new-search__locations {
  &_row {
    display: flex;
    align-items: center;
    width: 100%;
    height: 55px;
    padding-right: 28px;
    outline: none;

    @include arrow(10px, $colour-heathered-grey, 2px) {
      margin-left: auto;
      transform: rotate(-45deg);
      margin-right: -18px;
    }
  }

  &__label {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &_placeholder {
    font-size: 15px;
    color: $colour-heathered-grey;
  }

  &_names {
    @include ellipsis;
    color: $colour-cocoa-brown;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}
