.calculator-mask-inp {
  font-family: $helvetica;

  &__price {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    height: 50px;
    width: 100%;
    margin: 30px 0 15px;
    padding: 0 15px;
    align-items: center;
    color: #09101d;
    font-size: 14px;
    font-weight: 500;
    border-radius: 7px;
    border: 1px solid #e8ecf1;

    &-amount {
      font-size: 20px;
      font-weight: 400;
      min-width: 150px;
      text-align: right;

      @media screen and (max-width: 350px) {
        min-width: 130px;
        font-size: 17px;
      }
    }

    span {
      display: block;
    }
  }

  &__first-payment {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    height: 50px;
    width: 100%;
    padding: 0 15px;
    border-radius: 7px;
    background-color: #e8ecf1;
    color: #09101d;
    font-size: 14px;
    font-weight: 500;

    input {
      outline: none;
      height: 48px;
      max-width: 50%;
      font-size: 20px;
      text-align: right;
      color: #2e3131;
      background-color: inherit;
      border-width: 0;
    }

    input:focus {
      outline: none;
    }

    &-result {
      font-size: 20px;
      font-weight: 400;
      text-align: right;
      min-width: 140px;

      @media screen and (max-width: 350px) {
        min-width: 120px;
        font-size: 17px;
      }
    }

    span {
      display: block;
    }
  }
}
