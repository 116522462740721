.bottom-phone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  color: #fff;
  line-height: 1;
  font-size: 15px;
  text-decoration: none;
  border-radius: 7px;
  background-color: #3a54f7;
  box-sizing: border-box;

  &-container {
    display: flex;
    align-items: center;
    height: 90px;

    &--additional-space {
      margin-top: -10px;
      padding: 15px 15px 25px;
      height: 50px;
      background-color: #fff;
      box-sizing: content-box;
    }

    &--current-item-page {
      height: 78px;
      margin: 0;
      padding: 0 15px;
      background-color: #fff;
      border-bottom: 1px solid #eae5e1;
    }
  }

  &--color-green {
    background: #3db460;
  }

  &--disabled {
    background: #a5c5fc;
  }

  &__wrapper {
    width: 100%;

    &--sticky {
      position: fixed;
      bottom: calc(env(safe-area-inset-bottom) + 10px);
      left: 15px;
      right: 15px;
      width: auto;
      z-index: 3;
    }
  }

  &--link {
    &::before {
      content: '';
      background-repeat: no-repeat;
      display: block;
      width: 18px;
      height: 18px;
      margin-right: 10px;
      background-image: url('/static/images/phone_call_white-icon.svg');
    }
  }
}
