.calc-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #09101d;
  font-family: $helvetica;
  font-size: 12px;
  margin-top: 10px;

  &__item {
    display: flex;
    gap: 5px;

    i {
      display: block;
    }

    span {
      display: block;
    }
  }
}
