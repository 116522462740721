.management-section {
  display: flex;
  gap: 10px;
  font-family: $helvetica;
  font-size: 15px;

  a {
    text-decoration: none;
    text-underline: none;
  }

  &__btn {
    display: block;
    width: 100%;
    padding: 16px 15px;
    height: 50px;
    text-align: center;
    border-radius: 7px;
    cursor: pointer;

    &--edit {
      color: #4264fd;
      background-color: #dae8ff;
    }

    &--remove {
      color: #ff586d;
      background-color: #ffe6e5;
    }
  }
}
