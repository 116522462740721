$f-side-padding: 15px;
$f-border-radius: 3px;

@mixin f-input {
  height: 40px;
  border: 1px solid $colour-grey-wild-sand;
  background-color: $colour-white;
  font-size: 15px;
  color: $colour-cocoa-brown;
  white-space: nowrap;
}

.f-input {
  display: inline-block;
  font-size: 0;
  -webkit-tap-highlight-color: transparent; // eslint-disable-line
  backface-visibility: hidden;

  &-group {
    margin-bottom: 13px;

    &--flex {
      display: flex;
    }

    &--scrollable {
      margin-left: -($f-side-padding);
      margin-right: -($f-side-padding);
      padding-left: $f-side-padding;
      padding-right: $f-side-padding;
      scrollbar-width: none; // hides scrollbar on firefox
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
  }

  &__field {
    display: none;

    &:checked ~ span {
      border: 1px solid $colour-neon-blue;
      background-color: $colour-neon-blue;
      color: $colour-white;
    }
  }

  &__label {
    @include f-input;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 15px;
    letter-spacing: -0.4px;
    transition:
      color,
      background-color cubic-bezier(0, 0.15, 0, 1) 0.2s;
  }

  &--text-fields {
    @include f-input;
    display: block;
    padding: 5px 0;
    border-radius: $f-border-radius;
  }

  &__title {
    margin-bottom: 5px;
    font-size: 11px;
    font-weight: 500;
    color: $colour-heathered-grey;
    text-transform: uppercase;
  }

  &__text-field {
    display: inline-block;
    width: 50%;
    height: 100%;
    padding: 0 10px;
    font-size: 15px;
    color: $colour-cocoa-brown;
    border: 0;
    outline: none;
    border-radius: 0;

    ::placeholder {
      color: $colour-heathered-grey;
      opacity: 1;
    }

    + #{&} {
      border-left: 1px solid $colour-grey-wild-sand;
    }
  }
}
