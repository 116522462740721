$title-color: #98918a;

.categories {
  &-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: auto;
    width: 100%;
    margin: 5px 0;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.category {
  &-container {
    display: flex;
    min-width: 65px;
    height: 75px;
    padding: 5px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    white-space: break-spaces;

    &:nth-child(2) {
      min-width: 85px;
    }
  }

  &__icon {
    padding: 10px;
  }

  &__text {
    width: max-content;
    text-align: center;
    color: $title-color;
    font-size: 12px;
    font-weight: 400;
    margin-top: 3px;
  }
}
