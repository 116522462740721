.search-radio {
  &:checked {
    ~ div {
      color: $colour-neon-blue;
    }

    ~ span {
      border: 7px solid $colour-neon-blue;
    }
  }

  &-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px 15px;
    background-color: $colour-white;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: calc(100% - 15px);
      height: 1px;
      background-color: $colour-grey-desert-storm;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }

  &__text {
    font-size: 15px;
    color: $colour-cocoa-brown;
    font-family: $helvetica;
  }

  &__mark {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid $colour-white-smoke;
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;
  }
}

.input-hidden {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: -1;
}

.current-item-radio {
  &:checked {
    ~ div {
      color: $colour-neon-blue;
    }

    ~ span {
      border: 1px solid $colour-neon-blue;
      background: radial-gradient(circle,
      $colour-neon-blue 0%,
      $colour-neon-blue 40%,
      transparent 50%,
      transparent 100%);
    }
  }
}
