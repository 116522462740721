.city {
  padding: 10px 0;

  &__label {
    color: #98918a;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
  }

  &__field {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__name {
    color: #2f1f19;
    font-size: 15px;
    font-weight: 400;
  }

  &__container {
    height: 55px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include arrow(10px, $colour-heathered-grey, 2px) {
      margin-left: auto;
      transform: rotate(45deg);
      margin-right: 7px;
    }
  }

  &__loader {
    margin: 0;
    text-decoration: underline;

    span {
      width: 15px;
      height: 15px;
      margin: 0;
    }
  }
}
